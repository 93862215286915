@mixin section-padding {
    padding: rem-calc(73) 0;
    @include breakpoint(xlarge) {
        padding: rem-calc(110) 0;
    };
}

@mixin transition-base {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

@mixin transition-hover {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}


.icon {
    display: inline-block;
    &.substanz-werterhalt {
        background: url("../img/icon-substance-active.svg") no-repeat center center;
        height: 45px;
        width: 45px;
        background-size: 100% 100%;
    }
    &.bauen-renovieren {
        background: url("../img/icon-build-active.svg") no-repeat top center;
        height: 66px;
        width: 71px;
        background-size: 55% 55%;
    }
    &.leerstandsrisiko-ertragspruefung {
        background: url("../img/icon-empty-active.svg") no-repeat top center;
        height: 55px;
        width: 52px;
        background-size: 55% 55%;
    }
    &.abdichten-maengel {
        background: url("../img/icon-deficit-active.svg") no-repeat top center;
        height: 69px;
        width: 72px;
        background-size: 55% 55%;
    }
}


.large-5.shrink {
    width: 100%;
    @include breakpoint(medium) {
        width: 50%;
    };
    @include breakpoint(large) {
        width: 36%;
    };
}

.large-8.shrink {
    width: 100%;
    padding-left: 0;
    @include breakpoint(large) {
        width: 64%;
    };
}

.mobile-expand {
    padding: 0;
    @include breakpoint(medium) {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    };
    @include breakpoint(large) {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    };
    @include breakpoint(xlarge) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    };

}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: visible;
    -webkit-transform: translate3d(0, 0, 0)
}

.owl-dots {
    @include horizontal-center;
    margin-top: rem-calc(50);
}

.owl-dot {
    background: $white-two;
    height: 14px;
    width: 14px;
    margin: 0 8px;
    display: inline-block;
    border-radius: 2px;
    &:hover {
        background: $charcoal-grey;
    }
    &.active {
        background: $cherry-red;
    }
}



.owl-item {
    opacity: 0;
    @include transition-hover;
}

.owl-item.active {
    opacity: 1;
}

.owl-prev {
    @include vertical-center;
    background: url('../img/arrow-prev-large-hover.svg') no-repeat center center;
    width: 18px;
    height: 29px;
    left: rem-calc(22);
    top: 190px;
    opacity: 0.45;
    @include breakpoint(medium) {
        top: 230px;
    };
    @include breakpoint(large) {
        @include vertical-center;
    };
    &.disabled {
        opacity: 0.1;

        &:hover {
                    cursor: default;
            opacity: 0.1;
        }
    }
    &:hover {
        opacity: 1;
    }
}

.owl-next {
    @include vertical-center;
    background: url('../img/arrow-next-large-hover.svg') no-repeat center center;
    width: 18px;
    height: 29px;
    right: rem-calc(22);
    top: 190px;
    opacity: 0.45;
    @include breakpoint(medium) {
        top: 230px;
    };
    @include breakpoint(large) {
        @include vertical-center;
    };
    &.disabled {
        opacity: 0.1;
        &:hover {
            opacity: 0.1;
        }
    }
    &:hover {
        opacity: 1;
    }
}



.featherlight:last-of-type {
    background: $black;
}

.featherlight .featherlight-content {
    position: static;
}

.featherlight .featherlight-close-icon {
    content: 'hello';
    text-align: right;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
    font-size: 20px;
    top: rem-calc(0);
    right: rem-calc(0);
    margin: 0;
    padding: rem-calc(10);
    .closer {
        font-size: rem-calc(14);
        font-weight: 300;
        display: inline-block;
        margin-right: rem-calc(24);
    }
    i {
        display: inline-block;
        @include vertical-center;
        right: rem-calc(16);
    }
}






html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: rem-calc(15);
    @include breakpoint(medium) {
        font-size: rem-calc(16);
    };
    @include breakpoint(xlarge) {
        font-size: rem-calc(20);
    };
}

p {
    line-height: 1.4;
    &.small {
        font-size: rem-calc(11);
        line-height: rem-calc(16);
        @include breakpoint(medium) {
            font-size: rem-calc(14);
            line-height: rem-calc(20);
        };
    }
}

h2 {
    position: relative;
    font-weight: 300;
    text-align: center;
    line-height: rem-calc(24);
    padding-bottom: rem-calc(15);
    margin-bottom: rem-calc(24);
    &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: - rem-calc(15);
        content: '';
        width: rem-calc(30);
        height: rem-calc(2);
        background: $cherry-red;
    }
    @include breakpoint(medium) {
        line-height: rem-calc(40);
        text-align: left;
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-left: auto;
            content: '';
            width: rem-calc(60);
            height: rem-calc(3);
            background: $cherry-red;
        }
    };
    @include breakpoint(xlarge) {
        line-height: rem-calc(56);
    };
    &.medium {
        font-size: rem-calc(28);
        line-height: rem-calc(39);
        &.center {
            text-align: center;
            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: - rem-calc(35);
                content: '';
                width: rem-calc(60);
                height: rem-calc(3);
                background: $cherry-red;
            }
        }
    }
}

h3 {
    font-family: $font-condensed;
    font-weight: 300;
    line-height: rem-calc(32);
    margin-bottom: rem-calc(17);
    position: relative;
    @include breakpoint(medium) {
        margin-bottom: rem-calc(27);
    };
    @include breakpoint(xlarge) {
        line-height: rem-calc(40);
    };
    span {

    }
    .ico {

        &.substance {
            &:before {
                content: '';
                background: url('../img/icon-substance-active.svg');
                display: inline-block;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                margin-right: 16px;
                position: relative;
                top: 10px;
                @include breakpoint(xlarge) {
                    background-size: 50px 50px;
                    width: 50px;
                    height: 50px;
                };
            }
        }
        &.build {
            &:before {
                content: '';
                background: url('../img/icon-build-active.svg');
                display: inline-block;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                margin-right: 16px;
                position: relative;
                top: 10px;
                @include breakpoint(xlarge) {
                    background-size: 50px 50px;
                    width: 50px;
                    height: 50px;
                };
            }
        }
        &.empty {
            &:before {
                content: '';
                background: url('../img/icon-empty-active.svg') no-repeat;
                display: inline-block;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                margin-right: 16px;
                position: relative;
                top: 10px;
                @include breakpoint(xlarge) {
                    background-size: 40px 40px;
                    width: 40px;
                    height: 50px;
                };
            }
        }
        &.deficit {
            &:before {
                content: '';
                background: url('../img/icon-deficit-active.svg');
                display: inline-block;
                background-size: 30px 30px;
                width: 30px;
                height: 30px;
                margin-right: 16px;
                position: relative;
                top: 10px;
                @include breakpoint(xlarge) {
                    background-size: 50px 50px;
                    width: 50px;
                    height: 50px;
                };
            }
        }

    }
}



h4 {
    font-family: $font;
    font-weight: 500;
    margin-bottom: rem-calc(27);
    line-height: rem-calc(24);
    @include breakpoint(xlarge) {
        line-height: rem-calc(28);
    };
}


.button {
    background: #eaeaea;
    border: solid 1px #cccccc;
    color: $charcoal-grey;
    font-weight: 400;
    font-size: rem-calc(15);
    line-height: rem-calc(28);
    padding: rem-calc(7) rem-calc(16);
    width: 100%;
    @include transition-base;
    @include breakpoint(medium) {
        width: auto;
        padding: rem-calc(7) rem-calc(54);
        font-size: rem-calc(16);
        line-height: rem-calc(28);
    };
    @include breakpoint(xlarge) {
        width: auto;
        padding: rem-calc(7) rem-calc(54);
        font-size: rem-calc(20);
        line-height: rem-calc(28);
    };
    &:hover {
        border: solid 1px $cherry-red;
    }
    &#toggle-form {
        width: 90%;
        @include breakpoint(medium) {
            width: auto;
        };
    }
    &.form-sub {
        width: 100%;
        margin-bottom: 0;
    }
}


.home {
    .navigation {
        ul {
            li:first-child {
                a {
                    &.text-link {
                        color: $charcoal-grey;
                    }
                    &.icon-link {
                        i {
                            opacity: 1;
                            display: block;
                        }
                        &:after {
                            @include horizontal-center;
                            bottom: -45px;
                            z-index: 99;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 20px 15px 0 15px;
                            border-color: $white transparent transparent transparent;
                            @include breakpoint(medium) {
                                bottom: -19px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.navigation {
    font-family: $font-condensed;
    text-align: center;
    font-size: rem-calc(20);
    margin-top: rem-calc(20);
    height: 80px;
    @include breakpoint(medium) {
        height: 128px;
        margin-top: rem-calc(0);
    };
    @include breakpoint(large) {
        height: 148px;
        margin-top: rem-calc(0);
    };
    @include breakpoint(xlarge) {
        height: 160px;
        margin-top: rem-calc(0);
        font-size: rem-calc(24);
    };
    ul {
        position: relative;
        height: 100%;
        display: block;
        list-style: none;
        line-height: rem-calc(26);
        margin: 0;
        @include breakpoint(xlarge) {
            line-height: rem-calc(32);
        };
        li {
            position: relative;
            display: block;
            float: left;
            width: 25%;
            height: 100%;
            padding: 0 rem-calc(0);
            @include breakpoint(xlarge) {
                padding: 0 rem-calc(0);
            };
            &:first-child {

            }
            &:last-child {

            }

            a {
                &.text-link {
                    display: none;
                    @include breakpoint(medium) {
                        position: relative;
                        display: block;
                        color: $disabled;
                    };
                }
                &.icon-link {
                    display: block;
                    height: 60%;
                    position: relative;
                    i {
                        @include absolute-center;
                        opacity: 0.5;
                        @include breakpoint(medium) {
                            opacity: 0;
                        }
                    }
                }
            }

            &:hover {
                a {
                    &.text-link {

                    }
                    &.icon-link {
                        i {
                            display: block;
                            opacity: 0.5;
                        }
                    }
                }
            }

            &.active {
                a {
                    &.text-link {
                        color: $charcoal-grey;
                    }
                    &.icon-link {
                        i {
                            opacity: 1;
                            display: block;
                        }
                        &:after {
                            @include horizontal-center;
                            bottom: -45px;
                            z-index: 99;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 20px 15px 0 15px;
                            border-color: $white transparent transparent transparent;
                            @include breakpoint(medium) {
                                bottom: -19px;
                            }
                        }
                    }

                }
            }

        }
    }



    .icon {
        &.substanz-werterhalt {
            background: url("../img/icons/icon-substance-active.svg") no-repeat top center;
            background-size: 100% 100%;
            height: 47px;
            width: 100%;
        }
        &.bauen-renovieren {
            background: url("../img/icons/icon-build-active.svg") no-repeat top center;
            background-size: 100% 100%;
            height: 47px;
            width: 100%;
        }
        &.leerstandsrisiko-ertragspruefung {
            background: url("../img/icons/icon-empty-active.svg") no-repeat top center;
            background-size: 100% 100%;
            height: 47px;
            width: 100%;
        }
        &.abdichten-maengel {
            background: url("../img/icons/icon-deficit-active.svg") no-repeat top center;
            background-size: 100% 100%;
            height: 47px;
            width: 100%;
        }
    }

}


.stage {
    &__header {
        @include breakpoint(medium) {
            position: fixed;
            z-index: 1000;
            background: $white;
            top: -7rem;
            width: 100%;
            padding: rem-calc(17) 0;
            border-bottom: 1px solid #dedede;
            @include transition-hover;
        }
        @include breakpoint(large) {
            top: -5rem;
        }
        &__brand {
            @include breakpoint(medium) {
                float: left;
                background: url("../img/logo-large.svg") no-repeat top center;
                background-size: (266px * 0.6) (42px * 0.6) ;
                width: (266px * 0.6);
                height: (42px * 0.6);
                margin-left: 0.925rem;
            }
            @include breakpoint(large) {
                background-size: (266px * 0.7) (42px * 0.7) ;
                width: (266px * 0.7);
                height: (42px * 0.7);
                margin-left: 1.25rem;
            }
            @include breakpoint(xlarge) {
                background-size: 200px 32px;
                width: 200px;
                height: 32px;
                margin-left: 1.25rem;
            }
        }
        &__navigation {
            @include breakpoint(medium) {
                float: left;
                margin-left: 0.925rem;
                width: rem-calc(603);
                margin-top: rem-calc(12);
            }
            @include breakpoint(large) {
                margin-top: 0;
                margin-left: rem-calc(30);
                width: rem-calc(650);
            }
            @include breakpoint(xlarge) {
                margin-top: 0;
                margin-left: rem-calc(60);
                width: rem-calc(780);
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                font-weight: normal;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                @include breakpoint(medium) {
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
                @include breakpoint(large) {
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                }
                @include breakpoint(xlarge) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(18);
                }
                li {
                    display: inline-block;
                    margin-right: rem-calc(20);
                    a {
                        color: #bfbfbf;
                        @include transition-hover;
                        &:hover {
                            color: $cherry-red;
                        }
                    }
                }
            }
        }
        &__label {
            @include breakpoint(medium) {
                position: absolute;
                right: 0px;
                top: rem-calc(21);
                background: url("../img/claim-copy.svg") no-repeat center center;
                background-size: 69px 19px;
                display: block;
                width: 69px;
                height: 19px;
            }
            @include breakpoint(large) {
                background-size: 86px 24px;
                display: block;
                width: 86px;
                height: 24px;
            }
        }
        &.is-visible {
            top: 0;
        }
    }

}


.cover {
    @include breakpoint(medium) {
        min-height: rem-calc(1090);
        height: 100vh;
    };
    @include breakpoint(large) {
        min-height: rem-calc(828);
        height: 100vh;
    };
    @include breakpoint(xlarge) {
        min-height: rem-calc(828);
        height: 100vh;
    };
    &__header {
        padding: rem-calc(15) 0;
        @include breakpoint(large) {
            position: absolute;
            top: rem-calc(30);
        };
        @include breakpoint(xlarge) {
            padding: 0;
            position: absolute;
            top: rem-calc(50);
        };
    }
    &__brand {
        &__logo {
            background: url("../img/logo-large.svg") no-repeat top center;
            background-size: (266px * 0.8) (42px * 0.8) ;
            display: block;
            width: (266px * 0.8);
            height: (42px * 0.8);
            @include breakpoint(large) {
                @include transition-hover;
                background-size: 266px 42px ;
                width: 266px;
                height: 42px;
            }
            h1 {
                text-indent: -999em;
            }
        }
    }
    &__content{
        margin-top: rem-calc(30);
        @include breakpoint(large) {
            @include absolute-center;
            position: relative;
            -webkit-transform: translate(-50%, -50%);
            margin: 0;
        }
        &__header {
        }
        &__intro {
        }
        &__video {
            position: relative;
            img {
                width: 100%;
                max-width: auto;
            }
            .video-play {
                opacity: 0.8;
                transition: all 0.2s ease-in-out;
                @include absolute-center;
                background: url("../img/icon-play.svg") no-repeat center center;
                height: 56px;
                width: 56px;
                background-size: contain;
                box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                @include breakpoint(medium) {
                    @include horizontal-center;
                    bottom: rem-calc(30);
                };
                @include breakpoint(large) {
                    bottom: auto;
                    @include absolute-center;
                };
                &:hover {
                    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.3);
                }
            }
            &.launchvideo {
                &:hover {
                    .video-play {
                        opacity: 1;
                    }
                }
            }
        }
        &__share {
            position: relative;
            float: right;
            margin-top: rem-calc(15);
            display: inline-block;
            padding: 5px 5px 0px 5px;
            border-radius: 4px;
            background-color: #ffffff;
            .share__icons {
                display: inline-block;
                a {
                    border-radius: 50%;
                    width: 28px;
                    height: 28px;
                    display: inline-block;
                    position: relative;
                    color: $white;
                    font-size: 1rem;
                    margin: 0 1px;
                    &.social {
                        opacity: 0;
                    }
                    i,
                    span {
                        @include absolute-center;
                    }
                    &.facebook {
                        background-color: #3b5998;
                    }
                    &.twitter {
                        background-color: #03b3ee;
                    }
                    &.google {
                        background-color: #e15440;
                    }
                    &.mail {
                        background-color: #e1dfdf;
                        color: #9a9a9a;
                    }
                    &.trigger-label {
                        color: $charcoal-grey;
                        &:hover span {
                            text-decoration: underline;
                        }
                    }
                    &.trigger {
                        padding-left: 35px;
                        font-size: 1.5rem;
                        background-color: none;
                        color: $charcoal-grey;
                        border-left: solid 1px $white;
                    }
                }
            }


            &.is-open {
                box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
                .trigger-label {
                    display: none;
                }
                a.social {
                    opacity: 1;
                }
                a.trigger {
                    border-radius: 0;
                    border-left: solid 1px #e5e5e5;
                }
            }
        }
        &__lead {
            position: relative;
            margin-top: rem-calc(16);
            margin-bottom: rem-calc(45);
            @include breakpoint(large) {
                margin-bottom: 0;
                position: absolute;
                bottom: 0;
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    &__cta {
        display: none;
        @include breakpoint(medium) {
            display: block;
        };
        @include breakpoint(large) {
            display: block;
        };
        @include horizontal-center;
        bottom: rem-calc(60);
        width: 28px;
        height: 42px;
        object-fit: contain;
        border-radius: 100px;
        border: solid 1px #cacaca;
        font-size: rem-calc(8);
        color: #adadad;
        i {
            @include horizontal-center;
            margin-left: -4px;
            animation: animationFrames 2s;
            animation-iteration-count: infinite;
            -webkit-animation: animationFrames 2s;
            -webkit-animation-iteration-count: infinite;
            -moz-animation: animationFrames 2s;
            -moz-animation-iteration-count: infinite;
            -o-animation: animationFrames 2s;
            -o-animation-iteration-count: infinite;
            -ms-animation: animationFrames 2s;
            -ms-animation-iteration-count: infinite;
        }
    }
}

@keyframes animationFrames {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 30px);
    opacity: 0;
  }
}
@-moz-keyframes animationFrames {
  0% {
    -moz-transform: translate(0px, 0px);
  }
  100% {
    -moz-transform: translate(0px, 30px);
    opacity: 0;
  }
}
@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: translate(0px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 30px);
    opacity: 0;
  }
}
@-o-keyframes animationFrames {
  0% {
    -o-transform: translate(0px, 0px);
  }
  100% {
    -o-transform: translate(0px, 10px);
  }
}
@-ms-keyframes animationFrames {
  0% {
    -ms-transform: translate(0px, 0px);
  }
  100% {
    -ms-transform: translate(0px, 10px);
  }
}


.section {
    overflow: hidden;
        @include section-padding;
        &__header {
            margin-bottom: rem-calc(10);
            @include breakpoint(medium) {
                margin-bottom: rem-calc(53);
            };
            @include breakpoint(xlarge) {
                margin-bottom: rem-calc(75);
            };
            &__title {
                h2 {

                }
            }
            &__lead {
                padding-top: rem-calc(6);
            }
        }
}

.services {
    background: $white-grey;
    padding-bottom: rem-calc(153);
    border-top: rem-calc(4) solid $light-gray;
    border-bottom: rem-calc(4) solid $light-gray;
    @include breakpoint(xlarge) {
        padding-bottom: rem-calc(185);
    }
    .section__header__title {
        h2 {
             word-spacing: 500px;
        }
    }
    .services-graphic {
        margin-top: 0.25rem;
        margin-bottom: 1rem;
        text-align: right;
        @include breakpoint(xlarge) {
            margin-top: 0.6rem;
        }
        img {
            margin-bottom: rem-calc(6);
            -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
            box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
        }
    }
    a.lightbox {
        color: $disabled;
        position: relative;
        padding-right: rem-calc(24);
        i {
            margin-left: rem-calc(6);
            font-size: rem-calc(20);
            @include vertical-center;
            right: 0;

        }
    }
}

.offer {
    .items {
        margin-bottom: rem-calc(15);
        @include breakpoint(medium) {
            margin-bottom: rem-calc(70);
        }
        .item {
            position: relative;
            display: block;
            height: 160px;
            margin-bottom: rem-calc(45);
            @include breakpoint(medium) {
                height: 160px;
                margin: 0;
            }
            @include breakpoint(large) {
                height: 230px;
            }
            @include breakpoint(xlarge) {
                height: 240px;
            }
            &__img {
                @include horizontal-center;
                width: rem-calc(96);
                height: rem-calc(96);
                border: 1px solid $white-two;
                background: #fdfdfd;
                border-radius: 50%;
                display: block;
                margin-bottom: rem-calc(20);
                @include breakpoint(large) {
                    width: rem-calc(160);
                    height: rem-calc(160);
                }
                img {
                    @include absolute-center;
                    width: 40%;
                    @include breakpoint(large) {
                        width: auto;
                    }
                }
            }
            &__label {
                @include horizontal-center;
                bottom: 0;
                text-align: center;
                width: 120px;
                padding: 0 rem-calc(20);
                @include breakpoint(large) {

                }
                @include breakpoint(xlarge) {
                    padding: 0;

                }
                h5 {
                    font-size: rem-calc(16);
                    line-height: rem-calc(20);
                    @include breakpoint(xlarge) {
                        font-size: rem-calc(20);
                        line-height: rem-calc(24);
                    }
                }
            }
        }
    }
    .form {
        position: relative;
        height: 44px;
        margin-bottom: rem-calc(45);
        @include breakpoint(medium) {
            margin-bottom: rem-calc(94);
        }
        #toggle-form {
            @include horizontal-center;
            z-index: 9;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
            font-size: rem-calc(16);
            line-height: rem-calc(28);
            @include breakpoint(xlarge) {
                font-size: rem-calc(20);
                line-height: rem-calc(28);
            }
        }
    }

    #form-a,
    #form-b {
        display: none;
        position: relative;
        top: rem-calc(-68);
        background: $white-grey;
        padding-bottom: rem-calc(123);
        border-top: rem-calc(4) solid $light-gray;
        border-bottom: rem-calc(4) solid $light-gray;
        height: 100%;
        @include breakpoint(medium) {
            top: rem-calc(-116);
            padding-bottom: rem-calc(123);
        }
    }

    #form-a {
        @include section-padding;
        padding-bottom: rem-calc(0);
        @include breakpoint(medium) {
            @include section-padding;
        }
        .section__header {
            margin-bottom: rem-calc(10);
            @include breakpoint(xlarge) {
                margin-bottom: rem-calc(20);
            }
        }
    }

    #form-b {
        top: rem-calc(-78);
        @include section-padding;
        padding-bottom: rem-calc(0);
        @include breakpoint(medium) {
            @include section-padding;
        }
        @include breakpoint(xlarge) {
            top: rem-calc(-38);
        }
        .section__header {
            margin-bottom: rem-calc(10);
            @include breakpoint(xlarge) {
                margin-bottom: rem-calc(20);
            }
        }
    }


    .button.animate {

        &::after {
            @include horizontal-center;
            position: absolute;
            bottom: -19px;
            z-index: 99;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0px 30px 20px 30px;
            border-color: $cherry-red transparent transparent transparent;
            @include transition-hover;
        }
        &.active {
            background: $cherry-red;
            color: $white;
            &::after {
                border-width: 20px 15px 0 15px;
            }
            &:hover {

            }
        }
    }


    .offer-alt {
        margin-bottom: rem-calc(40);
        @include breakpoint(xlarge) {
            margin-bottom: rem-calc(0);
        };
        h2 {
            width: 70%;
            margin-bottom: rem-calc(60);
            @include breakpoint(medium) {
                width: 28%;

            }
            @include breakpoint(xlarge) {
                width: 23%;
            }
        }
    }

    .packages {
        padding: 0 rem-calc(50);
        margin-right: 10px;
        @include breakpoint(large) {
            padding: 0 rem-calc(50);
        };
        @include breakpoint(xlarge) {
            padding: 0 rem-calc(30);
        };
        h2 {
            @include horizontal-center;
            width: 280px;
        }
        .owl-item {

        }
        .owl-dots {
            margin-top: rem-calc(40);
            &.hide {
                display: none;
            }
        }
        .owl-prev {
            left: 0;
            @include vertical-center;
        }
        .owl-next {
            right: 0;
            @include vertical-center;
        }
        .owl-stage-outer {
            margin-left: -1px;
        }
        .package {
            border-radius: 18px;
            border: solid 1px #dedede;
            padding: rem-calc(12);
            position: relative;
            float: left;
            &__header {
                h3 {
                    font-family: $font;
                    font-weight: 500;
                    font-size: rem-calc(18);
                    line-height: rem-calc(24);
                    margin-bottom: rem-calc(16);
                }
            }
            &__description {
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                position: relative;
                margin-bottom: rem-calc(120);
                hyphens: auto;
            }
            &__price {
                position: absolute;
                bottom: 0;
                right: rem-calc(12);
                margin-bottom: rem-calc(50);
                text-align: right;
                font-size: rem-calc(16);
                line-height: rem-calc(28);
                span {
                    font-size: rem-calc(24);
                    font-weight: 700;
                }
            }
            &__cta {
                position: absolute;
                bottom: rem-calc(12);
                left: rem-calc(0);
                right: rem-calc(0);
                padding: 0 rem-calc(12);
                z-index: 9;
                button {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                    margin: 0;
                    font-size: 1rem;
                    @include breakpoint(xlarge) {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}

.packages-content {
    margin-bottom: 2rem;
    position: relative;
    @include transition-hover;
}

.package-content {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    @include transition-hover;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .footnote {
        color: $warm-grey;
        .small {
            font-size: 80%;
        }
        .center {
            text-align: center;
        }
        .left {
            text-align: left;
        }
        .right {
            text-align: right;
        }
    }
    .package-content-entry {
        padding-bottom: 1rem;
    }
}



.video {
    padding: rem-calc(36) 0 rem-calc(0) 0;
    position: relative;
    background: #404040;
    color: $white;
    @include breakpoint(medium) {
        padding-bottom: rem-calc(30);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    };
    @include breakpoint(large) {
        @include section-padding;
        height: 100vh;
        min-height: 628px;
    };
    .video-play {
        @include absolute-center;
        background: url("../img/icon-play.svg") no-repeat center center;
        height: 56px;
        width: 56px;
        background-size: contain;
        @include breakpoint(medium) {
            @include horizontal-center;
            top: auto;
            bottom: rem-calc(30);
        };
        @include breakpoint(large) {
            bottom: auto;
            @include absolute-center;
        };
    }
    .video-mobile {
        padding: 0;
        margin-bottom: rem-calc(27);
    }
    .section__header__title {
        margin-bottom: rem-calc(40);
        @include breakpoint(large) {
            h2 {
                text-align: left;
            }
        };
    }
}


.slideshow {
    padding: rem-calc(36) 0 rem-calc(0) 0;
    position: relative;
    background: #404040;
    color: $white;
    //cursor: pointer;
    &:hover {
        .slideshow-start {
            opacity: 1;
        }
    }
    @include breakpoint(medium) {
        padding-bottom: rem-calc(30);
        background-image: url("../img/screen_big_slider.jpg");

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    };
    @include breakpoint(large) {
        @include section-padding;
        height: 100vh;
        min-height: 960px;
    };
    .slideshow-mobile {
        padding: 0;
        margin-bottom: rem-calc(27);
        position: relative;
    }
    .section__header__title {
        margin-bottom: rem-calc(40);
        @include breakpoint(large) {
            h2 {
                text-align: left;
            }
        };
    }

}


.slideshow-start {
    @include absolute-center;
    background: url("../img/icon-photo.svg") no-repeat center center;
    height: 56px;
    width: 56px;
    background-size: contain;
    opacity: 0.8;
    @include transition-base;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.1);
    border-radius: 50%;
    @include breakpoint(medium) {
        @include horizontal-center;
        top: auto;
        bottom: rem-calc(30);
    };
    @include breakpoint(large) {
        bottom: auto;
        @include absolute-center;
    };
    &:hover {
        opacity: 1;
        box-shadow: 0px 0px 0px 5px rgba(255,255,255,.3);
    }
}

#lightgallery {
    display: none;
}

.lg-toolbar {
    background-color: rgba(0,0,0,.0);
}

.lg-sub-html {
    background-color: rgba(0,0,0,.5);
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    padding: 12px 18px;
    p {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        @include breakpoint(medium) {
            font-size: 16px;
            line-height: 20px;
        };
        @include breakpoint(large) {
            font-size: 20px;
            line-height: 28px;
        };
    }
}

.lg-toolbar .lg-close:after {

}






.crosslinks {
    padding: 0;
    .card {
        position: relative;
        overflow: hidden;
        background: $white-grey;
        padding: 0;
        &__wrapper {
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 2;
            height: 100%;
            min-height: 400px;
            width: 100%;
            &::after {
                @include transition-hover;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                min-height: 400px;
                background-image: linear-gradient(to bottom, rgba(29, 29, 27, 0.0), #1d1d1b);
            }
            &::before {
                @include transition-hover;
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                min-height: 400px;
                background: $white;
                opacity: 0;
            }
        }

        &--isactive {
            padding: 0;
            background: $white;
            color: $charcoal-grey;
            .card__title {
                position: static;
                .card-brand {
                    width: rem-calc(266);
                }
                margin-bottom: rem-calc(51);
            }
            .card__description {
                opacity: 1;
                position: relative;
                bottom: rem-calc(0);
                color: $charcoal-grey;
            }
            .card__wrapper {
                color: $charcoal-grey;
                position: relative;
                z-index: 2;
                height: 100%;
                width: 100%;
                &::after {
                    display: none;
                }
                &::before {
                    display: none;
                }
            }

        }

        &__title {
            position: absolute;
            bottom: rem-calc(35);
            @include transition-hover;
            color: $white;
            padding-right: 1.25rem;
            h5 {
                font-family: $font;
                font-weight: 300;
                font-size: rem-calc(14);
                line-height: rem-calc(22);
                margin-bottom: 0;
            }

            h6 {
                font-family: $font;
                font-weight: 500;
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                margin: 0;
            }
        }
        &__header {
            margin-bottom: rem-calc(50);
            .card-brand {
                width: rem-calc(266);
            }
        }
        &__content {
            position: relative;
            z-index: 2;
            height: 100%;
            width: 100%;
            min-height: 400px;
            padding: rem-calc(20) rem-calc(20) rem-calc(35) rem-calc(20);
            font-size: rem-calc(14);
            overflow: hidden;
            p {
                font-size: rem-calc(14);
                line-height: rem-calc(22);
            }
        }
        &__description {
            opacity: 0;
            position: relative;
            bottom: -80%;
            padding: rem-calc(80) rem-calc(20) rem-calc(0) rem-calc(0);
            font-size: rem-calc(14);
            @include transition-hover;
            p {
                font-size: rem-calc(14);
                line-height: rem-calc(22);
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &:hover {
            .card__title {
                bottom: 80%;
                color: $charcoal-grey;
            }
            .card__description {
                opacity: 1;
                position: absolute;
                bottom: rem-calc(35);
            }
            .card__wrapper::after {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to bottom, rgba(29, 29, 27, 0.0), #1d1d1b);
                    opacity: 0;
            }
            .card__wrapper::before {
                    opacity: 0.88;
            }
        }
    }
}

.footer {
    padding: 0;
    background: $white-grey;
    color: $warm-grey;
    a {
        color: $warm-grey;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline;
            margin-right: rem-calc(30);
            i.fa {
                font-size: rem-calc(16);
                margin-right: rem-calc(8);
            }
        }
    }
    hr {
        border-bottom: rem-calc(1) solid $warm-grey;
        width: 100%;
        max-width: 100%;
        opacity: 0.27;
        margin: rem-calc(8) 0 rem-calc(8) 0;
    }
    &__contacts {
        padding: rem-calc(16) rem-calc(0) rem-calc(0) rem-calc(0);
        a {
            font-weight: 700;
            font-size: rem-calc(14);
            @include breakpoint(medium) {
                font-size: rem-calc(16);
            };
            &:hover {
                text-decoration: underline;
            }
        }
        ul {
            &.contact-links {
                li {
                    display: block;
                    @include breakpoint(large) {
                        display: inline;
                    };
                }
            }
            &.social-icons {
                text-align: center;
                padding: rem-calc(40) 0;
                @include breakpoint(medium) {
                    text-align: right;
                    padding: 0;
                };
                li {
                    margin-right: rem-calc(0);
                    margin-left: rem-calc(8);
                    a {
                        @include transition-base;
                        background: #e3e3e3;
                        height: rem-calc(36);
                        width: rem-calc(36);
                        border-radius: 50%;
                        display: inline-block;
                        position: relative;
                        i {
                            @include absolute-center;
                        }
                        &:hover {
                            background: #d8d8d8;
                        }
                    }
                }
            }
        }
    }
    &__legal {
        font-size: rem-calc(12);
        padding: rem-calc(0) rem-calc(0) rem-calc(6) rem-calc(0);
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        p {
            line-height: rem-calc(22);
        }
        ul {
            li {
                display: inline;
                padding: 0 rem-calc(8);
                margin: 0;
                border-right: rem-calc(1) solid #afafaf;
                &:first-child {
                    border-right: none;
                    display: block;
                    @include breakpoint(medium) {
                        display: inline;
                        border-right: rem-calc(1) solid #afafaf;
                    };
                }
                &:last-child {
                    border: none;
                }
            }
        }
    }
}


form#fA,
form#fB {
    fieldset {
        margin: rem-calc(25) 0;
        &:last-child {
            margin-bottom: rem-calc(50);
            @include breakpoint(medium) {
                margin-bottom: 0;
            }
        }
    }
    h4 {
        font-weight: normal;
        margin-bottom: rem-calc(15);
        @include breakpoint(medium) {
            margin-bottom: rem-calc(34);
        }
    }
}


.form {
    &__field {
        position: relative;
        &__error {
            display: none;
            z-index: 9;
            @include vertical-center;
            right: rem-calc(16);
            font-size: rem-calc(20);
            color: $cherry-red;
        }
        &__success {
            display: none;
            z-index: 9;
            @include vertical-center;
            right: rem-calc(16);
            font-size: rem-calc(20);
            color: green;
        }
        label {
            font-size: rem-calc(16);
            line-height: rem-calc(33);
            font-weight: 300;
            color: $charcoal-grey;
            @include breakpoint(xlarge) {
                font-size: rem-calc(20);
            }
        }
    }


}

.form__row {
    position: relative;
    .form__label {
        label {
        }
    }
    &--plaintext,
    &--amforms_email {
        .form__field {
            position: relative;
            label {
                z-index: 9;
                @include vertical-center;
                width: 100%;
                color: #adadad;
                font-weight: 300;
                left: rem-calc(22);
                font-size: rem-calc(16);
                line-height: rem-calc(28);
                @include transition-base;
                @include breakpoint(xlarge) {
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                }
                &.comment-field {
                    top: rem-calc(22);
                }
            }

            input {
                padding: rem-calc(22);
                font-size: rem-calc(16);
                line-height: rem-calc(28);
                @include breakpoint(xlarge) {
                    font-size: rem-calc(20);
                }
                &:focus,
                &.hasText {
                    padding: rem-calc(29) rem-calc(22) rem-calc(15) rem-calc(22);
                }
                &:focus ~ label,
                &.hasText ~ label {
                    color: $charcoal-grey;
                    top: rem-calc(12);
                    font-size: rem-calc(10) !important;
                }
                &.is-invalid-input:not(:focus) {
                    background: $white;
                    border: 1px solid #cccccc;
                }
                &:valid {

                }
            }
            textarea {
                padding: rem-calc(22);
                font-size: rem-calc(16);
                line-height: rem-calc(28);
                @include breakpoint(xlarge) {
                    font-size: rem-calc(20);
                }
                &:focus,
                &.hasText {
                    padding: rem-calc(13) rem-calc(22) rem-calc(15) rem-calc(22);
                }
                &:focus ~ label,
                &.hasText ~ label {
                    color: $charcoal-grey;
                    top: rem-calc(12);
                    font-size: rem-calc(10) !important;
            }

            }
        }
    }
    &--checkboxes {
        margin-bottom: rem-calc(30);
        .form__label {
            display: none;
        }
    }
    label.topics-field,
    label.contactMe-field,
    label.angebotspaket-field {
        display: none;
    }

    textarea:disabled {
        background: $white;
    }
}



input {
   &[type=radio] {
      opacity: 0;
      position: absolute;
      margin: 11px;
      &:checked + label:before {
         background: $primary-color;
         -webkit-box-shadow: inset 0px 0px 0px 5px rgba(242,242,242,1);
         -moz-box-shadow: inset 0px 0px 0px 5px rgba(242,242,242,1);
         box-shadow: inset 0px 0px 0px 5px rgba(242,242,242,1);
      }

      & + label:before {
         display: inline-block;
         text-align: center;
         line-height: rem-calc(22);
         border: 1px solid #ccc;
         width: rem-calc(22);
         height: rem-calc(22);
         font-size: 1.875rem;
         color: #f2f2f2;
         background: #f2f2f2;
         margin-right: 13px;
         @include breakpoint(medium) {
             margin-bottom: -4px;
         }
      }
   }

   &[type=radio] {
      & + label:before {
         content: '';
         border-radius: 50%;
      }
   }
}



input {
   &[type=checkbox] {
      opacity: 0;
      position: absolute;
      &:checked + label:before {
        background: $primary-color;
        border: solid 1px $primary-color;
        color: $white;
      }

      & + label {
          margin: 0;
      }

      & + label:before {
        display: inline-block;
        text-align: center;
        line-height: 1;
	    border: solid 1px #cccccc;
        width: rem-calc(22);
        height: rem-calc(22);
        margin-right: rem-calc(13);
        font-size: 1rem;
        color: #f2f2f2;
      }
   }

   &[type=checkbox] {
      & + label:before {
        font-family: FontAwesome;
        content: "\f00c";
        border-radius: 4px;
        padding: 2px 0 0 2px;
      }
   }
}


.video-play {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
    @include absolute-center;
    background: url("../img/icon-play-large.svg") no-repeat center center;
    height: 72px;
    width: 72px;
    background-size: contain;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    @include breakpoint(medium) {
        @include horizontal-center;
        bottom: rem-calc(30);
    };
    @include breakpoint(large) {
        bottom: auto;
        @include absolute-center;
    };
    &:hover {
        opacity: 1;
        box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.3);
    }
}

.video-wrapper {
    position: relative;
    width: 100%;
	padding-bottom: 56.25%;
	height: 0;
    margin-bottom: 2rem;
    @include breakpoint(medium) {
        margin-bottom: 0rem;
    };
    iframe {
        box-shadow: 0 4px 40px 0 rgba(0,0,0,0.90);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}
