@import 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400|Roboto:300,400,500,700';


$cherry-red: #e2001a;
$charcoal-grey: #3d3e40;
$white: #ffffff;
$white-grey: #f2f2f2;
$white-two: #dcdbdb;
$warm-grey: #8f8f8f;
$disabled: #a0a0a0;

$font: 'Roboto', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font-condensed: 'Roboto Condensed', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;


$icon-fa-l: 1.4255;
